import React from 'react';
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';
import { getSurveyOriginationMethodValue, SurveyTimestamps } from "@/common/SurveyUtils";
import InputCell from '@/common/form/InputCell';
import { canQASurveys, isPrintSurveyEnabled } from "@/shared/access";

import { SurveyResultsAnswerChoice, SurveyResultsAnswerScale, SurveyResultsAnswerComment } from "../survey/SurveyResultsAnswerCell"
import SurveyResultsDetailsAttribute from "../survey/SurveyResultsDetailsAttribute"
import { MessageCellBot, MessageCellIncoming } from "../conversations/MessageCell";
import AddTask from "../tasks/AddTask";
import EncounterDetails from "../encounter/EncounterDetails";

function SurveyResults({ surveyInstanceId, onChange, opened, setAutoOpenModal,onModalClose, ...props }) {
    const [open, setOpen] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState("RESULTS");
    const [surveyInstance, setSurveyInstance] = React.useState(null);
    const [messages, setMessages] = React.useState([]);
    const [taskTypes, setTaskTypes] = React.useState([]);
    const [openIgnoreModal, setOpenIgnoreModal] = React.useState(false);
    const [answerIgnoreModal, setAnswerIgnoreModal] = React.useState(null);
    const [questionIgnoreModal, setQuestionIgnoreModal] = React.useState(null);

    React.useEffect(() => {
        if (open) loadData();
        else if (opened) setOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const loadData = () => {
        setSurveyInstance(null);
        API.get('Core', '/api/v1/survey/instance/' + surveyInstanceId)
            .then(response => {
                setSurveyInstance(Object.assign({}, response));
            },
                error => {
                    toast("Could not load survey results: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );

        setMessages([]);
        API.get('Core', '/api/v1/survey/instance/' + surveyInstanceId + '/messages')
            .then(response => {
                setMessages(Object.assign([], response));
            },
                error => {
                    toast("Could not load survey transcript: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );

        if (taskTypes == null || taskTypes.length === 0) {
            setTaskTypes([]);
            API.get('Core', '/api/v1/task/types')
                .then(response => {
                    setTaskTypes(Object.assign([], response));
                },
                    error => {
                    }
                );
        }
    }

    const clearReviewFlag = () => {
        surveyInstance.surveyInstance.review = false;
        API.put('Core', '/api/v1/survey/instance/' + surveyInstanceId, { body: surveyInstance.surveyInstance })
            .then(response => {
                setSurveyInstance(response);
            },
                error => {
                    toast("Could not update survey instance: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const addReviewFlag = () => {
        surveyInstance.surveyInstance.review = true;
        API.put('Core', '/api/v1/survey/instance/' + surveyInstanceId, { body: surveyInstance.surveyInstance })
            .then(response => {
                setSurveyInstance(response);
            },
                error => {
                    toast("Could not update survey instance: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const closeModal = () => {
        if (onModalClose) {
            onModalClose();
        }
        if (onChange) {
            onChange(surveyInstance);
        }
        setOpen(false);
        if (setAutoOpenModal && typeof setAutoOpenModal === 'function') setAutoOpenModal(false);
    }

    const onAddTaskSubmit = (newTask) => {
        newTask.sourceSurvey = surveyInstance.surveyInstance;
        newTask.sourceEncounter = surveyInstance.ruleTriggerInstance != null ? surveyInstance.ruleTriggerInstance.encounter : null;
        API.post('Core', '/api/v1/task', { body: newTask })
            .then(response => {
                toast("Task created!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Task creation failure!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }


    const getTabTitle = (tabKey) => {
        // eslint-disable-next-line default-case
        switch (tabKey) {
            case "RESULTS":
                return "Results";
            case "MESSAGES":
                return "Messages";
        }
    }

    const tabs = [
        "RESULTS",
        "MESSAGES"
    ]

    const showIgnoreModal = (answer, question) => {
        setAnswerIgnoreModal(answer);
        setQuestionIgnoreModal(question);
        setOpenIgnoreModal(true);
    }

    const updateAnswerIgnored = (answer, { ignored, ignoredComment }) => {
        API.put("Core", `/api/v1/survey/answer/ignore/${answer.id}`, {
            body: {
                ignored,
                ignoredComment,
            },
        }).then(
            (response) => {
                mutateAnswer(answer, { ignored: response.ignored, ignoredComment: response.ignoredComment });
                toast(`Answer ${ignored ? "marked" : "unmarked"} as ignored`, {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.SUCCESS,
                });
            },
            (error) => {
                toast("Answer update failure", {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.ERROR,
                });
            }
        );
    }

    const setAnswerIgnored = (answer, ignored, question) => {
        if (ignored === true) {
            showIgnoreModal(answer, question);
            return;
        }

        updateAnswerIgnored(answer, { ignored, ignoredComment: answer.ignoredComment });
    }

    const mutateAnswer = (answer, props) => {
        const idx = surveyInstance.surveyInstance.answers.findIndex(a => a.id === answer.id);
        setSurveyInstance((state) => ({
            ...state,
            surveyInstance: {
                ...state.surveyInstance,
                answers: [
                    ...state.surveyInstance.answers.slice(0, idx),
                    {
                        ...state.surveyInstance.answers[idx],
                        ...props
                    },
                    ...state.surveyInstance.answers.slice(idx + 1)
                ]
            }
        }))
    }

    const getTabContents = (tabKey) => {
        if (!surveyInstance) {
            return null;
        }
        switch (tabKey) {
            case "RESULTS":
                return (
                    <div className="contentView">
                        {
                            _.map(_.sortBy(surveyInstance.surveyVersion.questions, function (qu) { return qu.questionOrder; }), function (question) {
                                let answer = _.find(surveyInstance.surveyInstance.answers, function (a) { return question.id === a.question.id });
                                switch (question.type) {
                                    case "COMMENT":
                                        return <SurveyResultsAnswerComment key={question.id} question={question} answer={answer} setAnswerIgnored={setAnswerIgnored} />
                                    case "CHOICE":
                                        return <SurveyResultsAnswerChoice key={question.id} question={question} answer={answer} setAnswerIgnored={setAnswerIgnored} />
                                    case "SCALE":
                                        return <SurveyResultsAnswerScale key={question.id} question={question} answer={answer} setAnswerIgnored={setAnswerIgnored} />
                                    default:
                                        return null;
                                }
                            })
                        }
                    </div>
                );
            case "MESSAGES":
                if (!messages) {
                    return null;
                }
                return (
                    <div className="contentView">
                        {
                            _.map(messages, (message, index) => {
                                let time = moment(moment.utc(message.createdAt)).local().calendar();
                                switch (message.type) {
                                    case "CONTACT":
                                        return (
                                            <MessageCellIncoming
                                                key={index}
                                                messageTimestamp={time}
                                                messageText={message.message}
                                            />
                                        )
                                    case "APP":
                                        return (
                                            <MessageCellBot
                                                key={index}
                                                messageTimestamp={time}
                                                messageText={message.message}
                                            />
                                        )
                                    default:
                                        return null;
                                }
                            })
                        }
                    </div>
                );
            default:
                return null;
        }
    }

    const IgnoreAnswerModal = ({ answer, open, question, handleCancel, handleConfirm }) => {
        const [comment, setComment] = React.useState(answer ? answer.ignoredComment : "");

        const onClickIgnore = () => {
            handleConfirm(comment);
        }

        const Content = () => {
            return (
                <div>
                    <div className={"alertBody"}>
                        <div className={"alertTitle"} style={{ textAlign: "left", marginBottom: "5px" }}>
                            Exclude Rating
                        </div>
                        <div style={{ color: "white", marginBottom: "10px" }}>
                            Please give a reason for excluding this rating
                        </div>
                        <div className={"alertMessage"} style={{ textAlign: "left" }}>
                            <div className={`tableCell titledSelectCell textarea`}>
                                <InputCell inputProps={{ autoFocus: true }}
                                    defaultValue={comment}
                                    onChange={e => setComment(e.target.value)}
                                    type="textarea"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"alertActions dualActions"}>
                        <div onClick={handleCancel} className={"alertAction button action medium"}>
                            <div className={"buttonTitle"}>Cancel</div>
                        </div>

                        <div onClick={onClickIgnore}
                            className={`alertAction button medium confirm`}>
                            <div className={"buttonTitle"}>Exclude</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Modal
                content={<Content />}
                dialogClassName={"alertModal"}
                size="medium"
                open={open}
            />
        )
    }

    const handleCancelIgnore = () => {
        setOpenIgnoreModal(false);
        setAnswerIgnoreModal(null);
        setQuestionIgnoreModal(null);
    }

    const handleConfirmIgnore = (comment) => {
        updateAnswerIgnored(answerIgnoreModal, { ignored: true, ignoredComment: comment });
        setOpenIgnoreModal(false);
        setAnswerIgnoreModal(null);
        setQuestionIgnoreModal(null);
    }

    let content = open ? (
        <div className="modalDialog splitView surveyResultsView">
            <IgnoreAnswerModal open={openIgnoreModal} answer={answerIgnoreModal} question={questionIgnoreModal} handleCancel={handleCancelIgnore} handleConfirm={handleConfirmIgnore} />
            <div className="mainView">
                <div className="navBar">
                    <div className="title">Details</div>
                    <div className="separator" />
                </div>
                {
                    surveyInstance ? (
                        <div className="scrollView">
                            <div className="contentView resultsDetails">
                                <SurveyResultsDetailsAttribute
                                    attributeTitle="Source"
                                    attributeDetail={getSurveyOriginationMethodValue(surveyInstance)}
                                />

                                <SurveyResultsDetailsAttribute
                                    attributeTitle="Contact"
                                    attributeDetail={surveyInstance.surveyInstance.contact.readableName}
                                />

                                {
                                    surveyInstance.originationMethod === "RULE_TRIGGER" && surveyInstance.ruleTriggerInstance ? (
                                        <SurveyResultsDetailsAttribute
                                            attributeTitle="Encounter"
                                            attributeDetail={(
                                                <EncounterDetails encounterId={surveyInstance.ruleTriggerInstance.encounter.id}>
                                                    <a style={{ cursor: 'pointer' }} href={"#"}>#{surveyInstance.ruleTriggerInstance.encounter.sourceRefId}</a>
                                                </EncounterDetails>
                                            )}
                                        />
                                    ) : null
                                }

                                {
                                    surveyInstance.originationMethod === "RULE_TRIGGER" && surveyInstance.ruleTriggerInstance ? (
                                        <SurveyResultsDetailsAttribute
                                            attributeTitle="Incident Number"
                                            attributeDetail={surveyInstance.ruleTriggerInstance.encounter.incidentNumber ? "#" + surveyInstance.ruleTriggerInstance.encounter.incidentNumber : "N/A"}
                                        />
                                    ) : null
                                }

                                <SurveyTimestamps surveyInstance={surveyInstance} messages={messages} />
                            </div>
                        </div>
                    ) : null
                }
            </div>

            <div className="detailView">
                <div className="navBar">
                    <div className="title">{surveyInstance ? surveyInstance.surveyInstance.surveyName : ""}</div>
                    <div className={`segmentedBar index${_.indexOf(tabs, activeTab)}`} style={{ width: "160px" }}>
                        {
                            _.map(tabs, function (t, idx) {
                                return (
                                    <div key={idx} onClick={() => setActiveTab(t)} className={`segment ${t === activeTab ? 'selected' : ''}`}>
                                        <div className="title">{getTabTitle(t)}</div>
                                    </div>
                                );
                            })
                        }
                        <div className="selection" style={{ width: (100 / tabs.length) + '%' }} />
                    </div>
                    <div className="separator" />
                </div>

                <div className="scrollView">
                    {getTabContents(activeTab)}
                </div>

                <div className="actionBar">
                    <div className="actions">
                        {isPrintSurveyEnabled() ?
                            <div onClick={() => window.print()} className="button medium tint">
                                <div className="title">Print</div>
                            </div> : null}

                        <AddTask props={props} taskTypes={taskTypes} onAdd={onAddTaskSubmit}>
                            <div className="button small action">
                                <div className="title">Add Task</div>
                            </div>
                        </AddTask>
                        {surveyInstance ?
                            (
                                surveyInstance.surveyInstance ? (
                                    surveyInstance.surveyInstance.review ?
                                        <div onClick={() => clearReviewFlag()} className="button small tint destructive">
                                            <div className="title">Clear Review Flag</div>
                                        </div> : null
                                ) : null
                            ) : null
                        }
                        {surveyInstance ?
                            (
                                surveyInstance.surveyInstance ? (
                                    !surveyInstance.surveyInstance.review && canQASurveys() ?
                                        <div onClick={() => addReviewFlag()} className="button small tint action">
                                            <div className="title">Mark For Review</div>
                                        </div> : null
                                ) : null
                            ) : null
                        }
                        <div onClick={() => closeModal()} className="button medium tint">
                            <div className="title">Done</div>
                        </div>
                    </div>

                    <div className="separator" />
                </div>
            </div>
        </div>
    ) : null;

    return (<Modal
        button={props.children}
        content={content}
        title={"Survey Results"}
        size="xxl"
        handleClose={() => closeModal()}
        handleOpen={() => setOpen(true)}
        open={open}
    />)
}

export default (SurveyResults);