import React from "react"
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import InputCell from "../common/form/InputCell";
import Modal from "../common/Modal";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import ShiftSelect from "./ShiftSelect";
import DepartmentSelect from "./DepartmentSelect";
import _ from "lodash";

export default function AddEditPersonnel({button, onSuccess, personnelId}) {
    const [open, setOpen] = React.useState(false);
    const [personnel, setPersonnel] = React.useState({});
    const [type, setType] = React.useState({});
    const [phone, setPhone] = React.useState("");
    const [shift, setShift] = React.useState({});
    const [department, setDepartment] = React.useState({});
    const emailRef = React.createRef();
    const personnelIdRef = React.createRef();
    const nameRef = React.createRef();

    let types = [
        {
            name: "Responder",
            id: "RESPONDER"
        },
        {
            name: "Call Taker",
            id: "CALLTAKER"
        },
        {
            name: "Investigator",
            id: "INVESTIGATOR"
        }
    ]

    React.useEffect(() => {
      if (open) {
        setDepartment({})
        setShift({})
        if (personnelId) {
          loadPersonnel();
        }
      }
    }, [open])

    const loadPersonnel = () => {
        API.get("Core", "/api/v1/personnel/" + personnelId)
            .then(response => {
                setPersonnel(response);
                setType(_.find(types, function(t){return t.id == response.type;}))
                setPhone(response.phone);
                setShift(response.shift);
                setDepartment(response.department);
            }, err => {
                toast("Could not load personnel: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            })
    }

    const clearState = () => {
        setPhone("");
        setType({});
        setShift({});
    }

    const createOrUpdate = () => {

        if(!type.id || !nameRef.current.value){
            toast("Must set type and name", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
            return;
        }

        let body = {
            email: emailRef.current.value,
            personnelId: personnelIdRef.current.value,
            name: nameRef.current.value,
            type: type.id,
            phone: phone,
            shift: shift,
            department: department
        }

        if(personnelId){
            API.put("Core", "/api/v1/personnel/" + personnelId, {body: body})
                .then(response => {
                    clearState()
                    onSuccess();
                    setOpen(false);
                }, err => {
                    toast("Could not update personnel: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
        }
        else{
            API.post("Core", "/api/v1/personnel", {body: body})
                .then(response => {
                    clearState()
                    onSuccess();
                    setOpen(false);
                }, err => {
                    toast("Could not create personnel: " + err.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
        }
    }

    const onTypeChange = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setType(obj);
                break;
        }
    }

    return (
        <Modal
            button={button}
            content={(
                <div>
                    <div className="content">
                        <div className="tableView">
                            <div className="tableSection sectionName">
                                <InputCell
                                    placeholder={""}
                                    defaultValue={personnel ? personnel.name : ""}
                                    required
                                    id="name"
                                    label="Name"
                                    inputRef={nameRef}
                                />
                            </div>
                            <div className="tableSection sectionName">
                                <InputCell
                                    placeholder={""}
                                    defaultValue={personnel ? personnel.email : ""}
                                    required
                                    id="email"
                                    label="Email"
                                    inputRef={emailRef}
                                />
                            </div>
                            <div className="tableSection sectionName">
                                <div className="tableCell inputCell phoneInput">
                                    <div className="title">Phone Number</div>
                                    <PhoneInput
                                        inputClass="tel-input"
                                        country={'us'}
                                        onlyCountries={['us']}
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                    />
                                </div>
                            </div>
                            <div className="tableSection sectionName">
                                <InputCell
                                    placeholder={""}
                                    defaultValue={personnel ? personnel.personnelId : ""}
                                    required
                                    id="personnelId"
                                    label="Personnel ID"
                                    inputRef={personnelIdRef}
                                />
                            </div>
                            {
                                personnelId ? null : (
                                    <div className="tableSection sectionName">
                                        <div className="tableCell titledSelectCell">
                                            <div className="title">Type</div>
                                            <Select className="selectCell responseGroupSelect" classNamePrefix="selectCell"
                                                    onChange={onTypeChange}
                                                    value={type}
                                                    options={types}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.id}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="tableSection">
                                <div className="sectionTitle">
                                    <div className="title">Shift</div>
                                    
                                </div>
                                <ShiftSelect onSelect={(shift) => setShift(shift)} value={shift}/>
                            </div>
                            <div className="tableSection">
                                <div className="sectionTitle">
                                    <div className="title">Department</div>
                                </div>
                                <DepartmentSelect onSelect={(department) => setDepartment(department)} value={department}/>
                            </div>
                        </div>
                    </div>
                    <div className="actionBar">
                        <div className="actions">
                            <div onClick={() => setOpen(false)} className="button dismiss medium">
                                <div className="title">Cancel</div>
                            </div>

                            <div onClick={() => createOrUpdate()} className={`button confirm medium`}>
                                <div className="title">Save</div>
                            </div>
                        </div>

                        <div className="separator"></div>
                    </div>
                </div>
            )}
            title={personnelId ? "Edit Personnel" : "Create Personnel"}
            size="small"
            handleClose={() => setOpen(false)}
            handleOpen={() => setOpen(true)}
            open={open}
        />
    )
}