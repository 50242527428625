import { Amplify, Auth, Cache } from 'aws-amplify';
import { FEDERATED_APP_CLIENT_ID, FEDERATED_OAUTH } from "./common/CacheKeys";
export function setupAmplify() {
    let federatedAppClient = Cache.getItem(FEDERATED_APP_CLIENT_ID);
    let oauth = Cache.getItem(FEDERATED_OAUTH);
    Amplify.configure({
        Auth: {
            region: window.__RUNTIME_CONFIG__.COGNITO_REGION,
            userPoolId: window.__RUNTIME_CONFIG__.COGNITO_POOL_ID,
            userPoolWebClientId: (federatedAppClient ? federatedAppClient : window.__RUNTIME_CONFIG__.COGNITO_CLIENT_ID),
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: (oauth ? oauth : {})
        },
        Analytics: {
            disabled: true
        },
        API: {
            endpoints: [
                {
                    name: "Core",
                    endpoint: window.__RUNTIME_CONFIG__.API_HOST,
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).accessToken.jwtToken}` }
                    }
                },
                {
                    name: "Unauthorized",
                    endpoint: window.__RUNTIME_CONFIG__.API_HOST
                }
            ]
        }
    })
}