import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Modal from "../../../common/Modal";
import {toast} from "react-toastify";
import {Chip, makeStyles} from "@material-ui/core";
import ConfirmModal from "../../../common/ConfirmModal";
import {API} from "aws-amplify";
import AddEditRecordConnector from "./AddEditRecordConnector";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'name', label: 'Connector Name', minWidth: 170 },
    { id: 'connector', label: 'Connector Type', minWidth: 170 },
    { id: 'department', label: 'Department', minWidth: 170 }
];

function RecordsConnectorsIndex( props ){
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [departments, setDepartments] = React.useState([]);
    const [connectors, setConnectors] = React.useState([]);

    const loadDepartments = () => {
        API.get("Core", "/api/v1/department")
            .then(response => {
                    setDepartments(response);
                },
                error => {
                    toast("Could not get departments: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    const loadRecordConnectors = () => {
        API.get("Core", "/api/v1/records/connectors/configured")
            .then(response => {
                    setConnectors(response);
                },
                error => {
                    toast("Could not get connectors: " + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                })
    }

    React.useEffect(() => {
        loadDepartments();
        loadRecordConnectors();
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConnectorAdd = (department) => {
        setOpen(false);
        loadRecordConnectors();
    };

    const handleEditClose = (department) => {
        editOpen[department.id] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditOpen = (department) => {
        editOpen[department.id] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (department) => {
        editOpen[department.id] = false;
        setEditOpen(Object.assign({}, editOpen));
        loadRecordConnectors();
    }

    const deleteConnector = (connectorId) => {
        API.del('Core', '/api/v1/records/connectors/configured/' + connectorId)
            .then(response => {
                    toast("Deleted Connector!", {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS});
                    loadRecordConnectors();
                },
                error => {
                    toast("Could not Delete Connector" + error.response.data.message, {position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR});
                }
            );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add Connector
                    </Button>)}
                    content={(<AddEditRecordConnector
                        departments={departments}
                        onCancel={handleClose}
                        onSuccess={handleConnectorAdd}
                    />)}
                    title="Add Connector"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {connectors.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];

                                        if(column.id == "department"){
                                            if(row.departmentId){
                                                let department = _.find(departments, function(department){return department.id == row.departmentId;})
                                                if(department){
                                                    value = (<Chip label={department.name} />)
                                                }
                                            }
                                            else{
                                                value = (<Chip label="All Departments" />)
                                            }

                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={(<Button variant="contained" color="primary" className={classes.button}>
                                                Edit
                                            </Button>)}
                                            content={(<AddEditRecordConnector
                                                departments={departments}
                                                existingConnector={row}
                                                onCancel={handleEditClose.bind(this,row)}
                                                onSuccess={handleEditSuccess.bind(this, row)}
                                            />)}
                                            title="Edit Connector"
                                            size="small"
                                            handleClose={handleEditClose.bind(this, row)}
                                            handleOpen={handleEditOpen.bind(this, row)}
                                            open={editOpen[row.id]}
                                        />
                                        <ConfirmModal
                                            title={"Delete Connector"}
                                            text={(<span>Are you sure you want to delete <b>{row.name}</b>?</span>)}
                                            onConfirm={deleteConnector.bind(this, row.id)}
                                            confirmTitle={"Delete"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default RecordsConnectorsIndex;
