import React, { Component } from 'react';
import {    Authenticator,
            useTheme,
            Text,
            View,
            Heading,
            useAuthenticator,
            ThemeProvider} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CustomVerifyContact from './CustomVerifyContact';
import { withRouter } from "react-router";
import _ from "lodash";
import CustomResetPassword from './CustomResetPassword';
import LegalDisclaimer from './LegalDisclaimer';

const components = {
    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={5}
                >
                    Sign in to your account
                </Heading>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <div>
                    <View textAlign="left" style={{display: 'flex', paddingTop: '0px', paddingRight: '40px', paddingBottom: '35px', paddingLeft: '40px'}}>
                        <p style={{fontSize: '12px'}}><span style={{color: '#828282'}}>Forgot you password?</span>  <span onClick={toResetPassword} style={{color: '#FF9900', cursor: 'pointer'}}>Reset password</span></p>

                    </View>
                    <View textAlign="center" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                        <LegalDisclaimer />
                    </View>
                </div>
            );
        },
    },
    ResetPassword: {
        Header() {
            return (
                <Heading level={5}>
                    Reset password
                </Heading>
            );
        },
    }
}
const formFields = {
    signIn: {
        username: {
            placeholder: 'Email*',
            isRequired: true,
            labelHidden: true
        },
        password: {
            placeholder: 'Password*',
            isRequired: true,
            labelHidden: true,
            hideShowPassword: false
        }
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'Code',
            isRequired: true,
            labelHidden: true
        },
        password: {
            placeholder: 'New Password',
            isRequired: true,
            labelHidden: true
        },
        confirm_password: {
            placeholder: 'Confirm Password',
            isRequired: true,
            labelHidden: true
        },
    },
    resetPassword: {
        username: {
            placeholder: 'Email*',
            isRequired: true,
            labelHidden: true
        }
    }
}

const theme = {
    name: 'heading-theme',
    tokens: {
        components: {
            heading: {
                // color: { value: '{colors.blue.80}' },
                5: {
                    fontSize: { value: '{fontSizes.medium}' },
                    fontWeight: { value: '{fontWeights.normal}' },
                },

            },
        },
    },
};



function AuthComponent(props) {
    var backgroundImageUrl = null;
    if (_.has(props, 'user.aboutMe.user') && props.user.aboutMe.user.lightTheme) {
        backgroundImageUrl = '/powerdms-engage-FKA-logo.svg';
    }
    else {
        backgroundImageUrl = '/powerdms-engage-FKA-logo-white.svg';
    }

    let logo = (<div style={{
        backgroundImage: `url("${backgroundImageUrl}")`,
        backgroundSize: '280px 80px',
        width: '280px',
        height: '80px'
    }} className="logo"></div>);

    return (
        <div className="gatekeeperView logIn">
            <div className="gatekeeperContentView">
                <a href="https://www.powerdms.com/power-engage" data-testid="signedInPlaceholderLogo">{logo}</a>
                <ThemeProvider theme={theme}>
                    <Authenticator
                        loginMechanisms={['email']}
                        // authData={props.childProps.authenticatedUser}
                        hideSignUp={true}
                        components={components}
                        formFields={formFields}>

                        <VerificationListener calculatedAuthState={props.childProps} {...props} />
                        <CustomVerifyContact override={'verifyContact'} />
                        <CustomResetPassword override={'forgotPassword'} {...props} />

                        {/*
                        <ConfirmSignIn />
                        <RequireNewPassword />
                        <ConfirmSignUp />
                        <TOTPSetup />

                        */}

                    </Authenticator>
                </ThemeProvider>
            </div>
        </div>
    );
}

class VerificationListener extends Component {
    componentDidUpdate() {
        let currentPath = _.trimStart(this.props.location.pathname, "/");
        // Sidebar shouldn't show unless logged in
        if (_.startsWith(currentPath, 'sign-up') && this.props.authState === "signIn") {
            this.props.onStateChange('signUp', this.props.authData);
        }
        else if (this.props.calculatedAuthState.isAuthenticated && this.props.calculatedAuthState.needsVerification) {
            this.props.onStateChange('verifyContact', this.props.authData);
        }
    }

    render() {
        return null;
    }

}

export default withRouter(AuthComponent);
