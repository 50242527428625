import React from 'react';

export default function PresenceIndicator({ color }) {

    let clzName = "led-green";

    switch (color) {
        case "YELLOW":
            clzName = "led-yellow";
            break;
        case "RED":
            clzName = "led-red";
            break;
        case "GREEN":
        default:
            clzName = "led-green";
            break;
    }

    return (
        <div className="led-box">
            <div className={clzName}></div>
        </div>
    )
}