import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import _ from 'lodash';

import AddEditUser from "@/components/AddEditUser";
import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import PresenceIndicator from "@/common/PresenceIndicator";
import InputCell from "@/common/form/InputCell";
import { fetchProvidersList, fetchRoles, fetchSystemPrivileges } from "@/shared/user/actions";

const mapStateToProps = state => ({
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    getProviders: () => dispatch(fetchProvidersList()),
    getSystemPrivileges: () => dispatch(fetchSystemPrivileges()),
    getRoles: () => dispatch(fetchRoles())
})
const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));

const columns = [
    { id: 'online', label: 'Online', minWidth: 50 },
    { id: 'firstName', label: 'First Name', minWidth: 170 },
    { id: 'lastName', label: 'Last Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'personnelId', label: 'Personnel ID', minWidth: 100 },
    { id: 'role', label: 'Role', minWidth: 100 },
    { id: 'department', label: 'Department', minWidth: 100 }
];

function TeamIndex(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [passwordResetUser, setPasswordResetUser] = React.useState(null);
    const [tempPassword, setTempPassword] = React.useState(null);
    const passwordRef = React.createRef();

    React.useEffect(() => {
        props.getProviders();
        props.getSystemPrivileges();
        props.getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUserInvite = (user) => {
        setOpen(false);
        props.getProviders()
    };

    const handleEditOpen = (userId) => {
        editOpen[userId] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditClose = (userId) => {
        editOpen[userId] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (userId) => {
        editOpen[userId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getProviders();
    }

    const deleteUser = (userId) => {
        API.del('Core', '/api/v1/users/provider/' + userId)
            .then(response => {
                props.getProviders();
                toast("Deleted user!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Could not delete user" + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const resetPassword = (user) => {

        API.put('Core', '/api/v1/users/provider/' + user.id + '/temporary-password')
            .then(response => {
                setPasswordResetUser(user);
                setTempPassword(response.temporaryPassword);
                toast("Successfully reset users password!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Could not reset password" + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const closePasswordReset = () => {
        const RESETTING_OWN_PASSWORD = props?.user?.aboutMe?.user?.id === passwordResetUser?.id;

        setPasswordResetUser(null);
        setTempPassword(null);
        if (RESETTING_OWN_PASSWORD) Auth.signOut();
    }

    const isOnline = (user) => {
        let found = _.find(props.user.onlinePresenceMembers, function (member) {
            return member.id === user.id;
        });
        return found;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Invite User
                    </Button>)}
                    content={(<AddEditUser
                        systemPrivileges={props.user.systemPrivileges}
                        onCancel={handleClose}
                        onSuccess={handleUserInvite}
                        roles={props.user.roles}
                    />)}
                    title="Add User"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                />
            </Grid>
            <Grid xs={12}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.user.providers.map(row => {

                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    {columns.map(column => {
                                        let value = row[column.id];
                                        if (column.id === 'role') {
                                            value = value.map(function (r) { return r.name; }).join(", ");
                                        }
                                        else if (column.id === 'online' && isOnline(row)) {
                                            value = (
                                                <PresenceIndicator />
                                            )
                                        }
                                        else if (column.id === 'department' && value) {
                                            value = value.name;
                                        }

                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell key={'edit'}>
                                        <Modal
                                            button={(<Button variant="contained" color="primary" className={classes.button}>
                                                Edit
                                            </Button>)}
                                            content={(<AddEditUser
                                                onCancel={handleEditClose.bind(this, row.id)}
                                                onSuccess={handleEditSuccess.bind(this, row.id)}
                                                roles={props.user.roles}
                                                existingUser={row}
                                            />)}
                                            title="Edit User"
                                            size="small"
                                            handleClose={handleEditClose.bind(this, row.id)}
                                            handleOpen={handleEditOpen.bind(this, row.id)}
                                            open={editOpen[row.id]}
                                        />
                                        <ConfirmModal
                                            title={"Delete User"}
                                            text={(<span>Are you sure you want to delete <b>{row.firstName} {row.lastName}</b>?</span>)}
                                            onConfirm={deleteUser.bind(this, row.id)}
                                            confirmTitle={"Delete User"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Delete
                                            </Button>
                                        </ConfirmModal>
                                        <ConfirmModal
                                            title={"Reset Password"}
                                            text={(<span>Are you sure you want to reset password for <b>{row.firstName} {row.lastName}</b>?</span>)}
                                            onConfirm={resetPassword.bind(this, row)}
                                            confirmTitle={"Reset Password"}
                                        >
                                            <Button variant="contained" color="primary" className={classes.button}>
                                                Reset Password
                                            </Button>
                                        </ConfirmModal>
                                        {
                                            tempPassword && passwordResetUser?.id === row.id ? (
                                                <Modal
                                                    content={(
                                                        <div>
                                                            <div className="content">
                                                                <div className="tableView">
                                                                    <div className="tableSection sectionName">
                                                                        <InputCell
                                                                            placeholder={""}
                                                                            defaultValue={passwordResetUser.email}
                                                                            id="tempEmail"
                                                                            label="Email"
                                                                        />
                                                                    </div>
                                                                    <div className="tableSection sectionName">
                                                                        <InputCell
                                                                            placeholder={""}
                                                                            defaultValue={tempPassword}
                                                                            id="tempPass"
                                                                            label="Password"
                                                                            inputRef={passwordRef}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="actionBar">
                                                                <div className="actions">
                                                                    <div onClick={closePasswordReset.bind(this, row)} className="button dismiss medium">
                                                                        <div className="title">Done</div>
                                                                    </div>
                                                                    {
                                                                        document.queryCommandSupported('copy') ? (
                                                                            <div onClick={() => {
                                                                                passwordRef.current.select();
                                                                                document.execCommand('copy')
                                                                                toast("Copied to clipboard!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                                                                            }} className={`button confirm medium`}>
                                                                                <div className="title">Copy Password to Clipboard</div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="separator"></div>
                                                        </div>
                                                    )}
                                                    title="Temporary Login Credentials"
                                                    size="small"
                                                    handleClose={closePasswordReset.bind(this, row)}
                                                    handleOpen={() => { }}
                                                    open={tempPassword && passwordResetUser?.id === row.id}
                                                />
                                            ) : null
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamIndex);
