import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Select from "react-select";
import _ from 'lodash';

import InputCell from "@/common/form/InputCell";
import TenantSelect from "@/components/TenantSelect";
import TenantDepartmentSelect from "@/components/TenantDepartmentSelect";

const CONNECTOR_INTERFACES = [
    {label: "Aciss", value: "ACISS"},
    {label: "ARMS", value: "ARMS"},
    {label: "Batch", value: "BATCH"},
    {label: "Central Square Pro API", value: "CENTRAL_SQUARE_PRO_API"},
    {label: "Central Square Pro", value: "CENTRAL_SQUARE_PRO"},
    {label: "Cincinnati 311", value: "CINCINNATI311"},
    {label: "CrimeStar", value: "CRIMESTAR"},
    {label: "Data Pursuit File", value: "DATA_PURSUIT_FILE"},
    {label: "eForce", value: "EFORCE"},
    {label: "EIS", value: "EIS"},
    {label: "File System", value: "FILE_SYSTEM"},
    {label: "Hexagon", value: "HEXAGON"},
    {label: "Hexagon Edge Frontier", value: "HEXAGON_EDGEFRONTIER"},
    {label: "IMC", value: "IMC"},
    {label: "IMC STD", value: "IMC_STD"},
    {label: "Impact RMS", value: "IMPACT_RMS"},
    {label: "Impact VCAD", value: "IMPACT_VCAD"},
    {label: "Impact VCAD STD", value: "IMPACT_VCAD_STD"},
    {label: "Inform CAD", value: "INFORM_CAD"},
    {label: "Inform CAD STD", value: "INFORM_CAD_STD"},
    {label: "Inform CAD File", value: "INFORM_CAD_FILE"},
    {label: "Intellitech", value: "INTELLITECH"},
    {label: "Kologik", value: "KOLOGIK"},
    {label: "Lawsoft", value: "LAWSOFT"},
    {label: "Lawsoft STD", value: "LAWSOFT_STD"},
    {label: "Mark43", value: "MARK43"},
    {label: "Mark43 API", value: "MARK43_API"},
    {label: "Motorola Premier One", value: "MOTOROLA_PREMIERONE_RDW"},
    {label: "Newark", value: "NEWARK"},
    {label: "Nexgen", value: "NEXGEN"},
    {label: "Niche", value: "NICHE"},
    {label: "Omnigo", value: "OMNIGO"},
    {label: "Sansio", value: "SANSIO"},
    {label: "South Sound 911 File", value: "SOUTH_SOUND_911_FILE"},
    {label: "One Solution", value: "ONE_SOLUTION"},
    {label: "One Solution STD", value: "ONE_SOLUTION_STD"},
    {label: "Power Engage XML", value: "POWER_ENGAGE_XML"},
    {label: "ProPhoenix", value: "PROPHOENIX"},
    {label: "Pulsiam", value: "PULSIAM_REPORTING_DATABASE"},
    {label: "Pulsiam Safety Net", value: "PULSIAM_SAFETY_NET"},
    {label: "RIMS", value: "RIMS"},
    {label: "RMS STD", value: "RMS_STD"},
    {label: "Samba Share", value: "SMB_SHARE"},
    {label: "Shield", value: "SHIELD"},
    {label: "SmartCop CTS", value: "SMART_COP_CTS"},
    {label: "Southern Software", value: "SOUTHERN_SOFTWARE"},
    {label: "Southern Software Standard", value: "SOUTHERN_SOFTWARE_STANDARD"},
    {label: "Sonoma", value: "SONOMA"},
    {label: "Spillman DB", value: "SPILLMAN_DB"},
    {label: "Spillman Flex", value: "SPILLMAN_FLEX"},
    {label: "Tac 10", value: "TAC_10"},
    {label: "Tac 10 STD", value: "TAC_10_STD"},
    {label: "Tiburon", value: "TIBURON"},
    {label: "Tiverton CAD", value: "TIVERTON_CAD"},
    {label: "Tyler New World Aegis", value: "NEW_WORLD_AEGIS"},
    {label: "Tyler New World Enterprise", value: "NEW_WORLD_ENTERPRISE"},
    {label: "Tyler New World Enterprise File STD", value: "NEW_WORLD_ENTERPRISE_FILE_STD"},
    {label: "USA Software", value: "USA_SOFTWARE"},
    {label: "Versaterm", value: "VERSATERM"},
    {label: "365 Labs", value: "365_LABS"},
    {label: "911 Pro CAD", value: "911_PRO_CAD"},
    { label: "Tyler New World Enterprise CAD", value: "NEW_WORLD_ENTERPRISE_CAD" }, // Deprecated in favor of NEW_WORLD_ENTEPRISE
];

const ENCOUNTER_TYPES = [
    { value: "CAD", label: "CAD" },
    { value: "RMS_CASE", label: "RMS CASE" },
    { value: "THREE_ONE_ONE", label: "311" }
];

const ENCOUNTER_TYPE_MODE = {
    "REAL_TIME": 0,
    "SNAPSHOT": 1
}

const defaultEncounterType = {
    EncounterType: "CAD",
    CronSchedule: "0/20 * * * * ?",
    Mode: ENCOUNTER_TYPE_MODE.REAL_TIME
}

export default function AddEditConnector({ onSuccess, agent, dataSources, existingConnector }) {
    const nameRef = React.createRef();
    const agencyFilterRef = React.createRef();
    const jurisdictionFilterRef = React.createRef();
    const vendorIdentifier = React.createRef();
    const [tenant, setTenant] = React.useState(null);
    const [selectedDataSource, setSelectedDataSource] = React.useState(dataSources && existingConnector ? _.find(dataSources, function (ds) { return ds.Id === existingConnector.agentDataSourceId }) : null);
    const [selectedConnectorInterface, setSelectedConnectorInterface] = React.useState(existingConnector ? _.find(CONNECTOR_INTERFACES, function (ci) { return ci.value === existingConnector.connectorInterface; }) : null);
    const [utc, setUtc] = React.useState(existingConnector && existingConnector.utc);
    const [pushToPowerConnect, setPushToPowerConnect] = React.useState(existingConnector && existingConnector.pushToPowerConnect);
    const [pushToEngage, setPushToEngage] = React.useState(existingConnector && (existingConnector.pushToEngage ?? true));
    const [encounterTypes, setEncounterTypes] = React.useState(existingConnector && existingConnector.encounterTypes && JSON.parse(existingConnector.encounterTypes).length
        ? JSON.parse(existingConnector.encounterTypes)
        : [defaultEncounterType]);
    const [department, setDepartment] = React.useState(null);

    const mutateEncounterType = (idx, update) => {
        setEncounterTypes(encounterTypes => ([
            ...encounterTypes.slice(0, idx),
            {
                ...encounterTypes[idx],
                ...update
            },
            ...encounterTypes.slice(idx + 1)
        ]))
    };

    const addEncounterType = () => {
        setEncounterTypes([
            ...encounterTypes,
            {
                ...defaultEncounterType,
                EncounterType: ""
            }
        ])
    }

    const removeEncounterType = (idx) => {
        setEncounterTypes(encounterTypes => ([
            ...encounterTypes.slice(0, idx),
            ...encounterTypes.slice(idx + 1)
        ]))
    }

    const normalizeEncounterTypes = (encounterType) => {
        return {
            ...encounterType,
            ExcludeCallTakerInfo: encounterType.EncounterType === "CAD" ? !!encounterType.ExcludeCallTakerInfo : undefined
        }
    }

    async function saveConnector(e) {
        e.preventDefault();
        const validEncounterTypes = encounterTypes.length > 0 && encounterTypes.every(encounterType => !!encounterType.EncounterType)
        if ((!existingConnector && !tenant) || !nameRef.current.value || !selectedDataSource || !selectedConnectorInterface || !validEncounterTypes) {
            toast("Must select tenant, data source, connector interface, enter name and encounter type.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        if ((pushToEngage == null || pushToEngage === false) && (pushToPowerConnect == null || pushToPowerConnect === false)) {
            toast("You must turn on one of the Power Connect Flags.", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
            return;
        }
        let connectorInfo = {
            name: nameRef.current.value,
            tenant: tenant,
            agentDataSourceId: selectedDataSource.Id,
            agencyFilter: agencyFilterRef.current.value,
            jurisdictionFilter: jurisdictionFilterRef.current.value,
            connectorInterface: selectedConnectorInterface.value,
            vendor: vendorIdentifier.current.value,
            encounterTypes: JSON.stringify(encounterTypes.map(normalizeEncounterTypes)),
            utc: !!utc,
            pushToPowerConnect: !!pushToPowerConnect,
            pushToEngage: !!pushToEngage,
            departmentId: department ? department.id : null
        };

        if (existingConnector) {
            await API.put('Core', '/api/v1/agents/' + agent.id + '/connectors/' + existingConnector.dataSourceId, { body: connectorInfo })
                .then(() => {
                    toast("Successfully updated connector!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                    onSuccess();
                })
                .catch((err) => {
                    toast(err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
        } else {
            await API.post('Core', '/api/v1/agents/' + agent.id + '/connectors', { body: connectorInfo })
                .then(() => {
                    toast("Successfully created a new connector!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                    onSuccess();
                })
                .catch((err) => {
                    toast(err.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                });
        }

    }

    const onDataSourceSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setSelectedDataSource(obj);
                break;
            case "clear":
                setSelectedDataSource(null);
                break;
            default:
        }
    }

    const onConnectorInterfaceSelect = (obj, action) => {
        switch (action.action) {
            case "select-option":
                setSelectedConnectorInterface(obj);
                break;
            case "clear":
                setSelectedConnectorInterface(null);
                break;
            default:
        }
    }

    const onEncounterTypeSelect = (option, action, idx) => {
        switch (action.action) {
            case "select-option":
                mutateEncounterType(idx, { EncounterType: option.value });
                break;
            default:
        }
    }

    const loadDepartment = (departmentId) => {
        API.get("Core", `/api/v1/department/${departmentId}`)
            .then(response => {
                    setDepartment(response);
                },
                error => {
                    toast("Could not get department: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                })
    }

    useEffect(() => {
        if (existingConnector && existingConnector.departmentId) loadDepartment(existingConnector.departmentId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Name</div>
                        </div>
                        <InputCell
                            placeholder={"Cool Connector"}
                            required
                            defaultValue={existingConnector ? existingConnector.datasourceName : ''}
                            id="connectorName"
                            label="Connector Name"
                            inputRef={nameRef}
                        />
                    </div>
                    {
                        !existingConnector ? (
                            <div className="tableSection sectionName">
                                <div className="sectionTitle">
                                    <div className="title">Tenant</div>
                                    <div className="subtitle">Tenant for the connector to be added to.</div>
                                </div>
                                <TenantSelect onSelect={(ten) => setTenant(ten)} value={tenant} />
                            </div>
                        ) : null
                    }

                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Department</div>
                        </div>
                        <TenantDepartmentSelect onSelect={(dep) => setDepartment(dep)} value={department}
                                                tenantId={existingConnector ? existingConnector.tenantId : tenant?.id} />
                    </div>


                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Data Source</div>
                            <div className="subtitle">Credentials for the Data Source will be entered on the agent.</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                                isDisabled={!dataSources}
                                onChange={onDataSourceSelect}
                                value={selectedDataSource ? selectedDataSource : "--"}
                                options={dataSources}
                                isClearable={true}
                                getOptionLabel={option => option.Name}
                                getOptionValue={option => option.Id} />
                    </div>

                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Connector Interface</div>
                        </div>
                        <Select className="selectCell" classNamePrefix="selectCell"
                                onChange={onConnectorInterfaceSelect}
                                value={selectedConnectorInterface ? selectedConnectorInterface : "--"}
                                options={CONNECTOR_INTERFACES}
                                isClearable={true}
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.value} />
                    </div>

                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Timezone</div>
                            <div className="subtitle">When this flag is off it means local time.</div>
                        </div>
                        <div onClick={() => {
                            setUtc(!utc);
                        }}
                             className={`tableCell switchCell ${utc ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">UTC</div>
                            <div className={`accessory accessorySwitch ${utc ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                    </div>

                    <div className="tableSection sectionName">
                        <div className="sectionTitle">
                            <div className="title">Power Connect Flags</div>
                            <div className="subtitle">These flags determine if the new Engage pipeline and parser will be used.</div>
                        </div>
                        <div onClick={() => {
                            setPushToPowerConnect(!pushToPowerConnect);
                        }}
                             className={`tableCell switchCell ${pushToPowerConnect ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Push to Power Connect</div>
                            <div className={`accessory accessorySwitch ${pushToPowerConnect ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                        <div onClick={() => {
                            setPushToEngage(!pushToEngage);
                        }}
                             className={`tableCell switchCell ${pushToEngage ? 'switchOn' : 'switchOff'}`}>
                            <div className="title">Push to Engage</div>
                            <div className={`accessory accessorySwitch ${pushToEngage ? 'on' : 'off'}`}>
                                <div className="switchThumb" />
                            </div>
                        </div>
                    </div>

                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Filters</div>
                            <div className="subtitle">Comma separated list of IDs right for this Connector Interface.</div>
                        </div>
                        <InputCell
                            placeholder={"Agency1, Agency2"}
                            defaultValue={existingConnector ? existingConnector.agencyFilter : ''}
                            required
                            id="agencyFilter"
                            label="Agency"
                            inputRef={agencyFilterRef}
                        />

                        <InputCell
                            placeholder={"Jurisdiction1, Jurisdiction2"}
                            defaultValue={existingConnector ? existingConnector.jurisdictionFilter : ''}
                            required
                            id="jurisdictionFilter"
                            label="Jurisdiction"
                            inputRef={jurisdictionFilterRef}
                        />
                    </div>

                    <div className="tableSection">
                        <div className="sectionTitle">
                            <div className="title">Vendor</div>
                        </div>
                        <InputCell
                            placeholder={"Vendor Identifier"}
                            defaultValue={existingConnector ? existingConnector.vendor : ''}
                            id="vendorIdentifier"
                            label="Vendor"
                            inputRef={vendorIdentifier}
                        />
                    </div>

                    <div className="tableSection sectionName" style={{ paddingBottom: "0px" }}>
                        <div className="sectionTitle">
                            <div className="title">Encounter Types</div>
                        </div>
                    </div>

                    {
                        encounterTypes.map((encounterType, idx) => (
                            <div key={idx} className="encounterTypeContainer">
                                <div className="encounterType">
                                    <div className="tableSection sectionName">
                                        <Select className="selectCell" classNamePrefix="selectCell"
                                                onChange={(option, action) => onEncounterTypeSelect(option, action, idx)}
                                                value={ENCOUNTER_TYPES.find(option => option.value === encounterType.EncounterType)}
                                                options={ENCOUNTER_TYPES}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value} />
                                    </div>

                                    <div className="tableSection sectionName" style={{ marginTop: "10px" }}>
                                        <div className="sectionTitle">
                                            <div className="title">Frequency</div>
                                        </div>
                                        <div onClick={() => mutateEncounterType(idx, { Mode: encounterType.Mode === ENCOUNTER_TYPE_MODE.REAL_TIME ? ENCOUNTER_TYPE_MODE.SNAPSHOT : ENCOUNTER_TYPE_MODE.REAL_TIME })}
                                             className={`tableCell switchCell ${encounterType.Mode === ENCOUNTER_TYPE_MODE.REAL_TIME ? 'switchOn' : 'switchOff'}`}>
                                            <div className="title">Realtime</div>
                                            <div className={`accessory accessorySwitch ${encounterType.Mode === ENCOUNTER_TYPE_MODE.REAL_TIME ? 'on' : 'off'}`}>
                                                <div className="switchThumb" />
                                            </div>
                                        </div>
                                        <InputCell
                                            placeholder={"* * * * *"}
                                            defaultValue={encounterType.CronSchedule || "0/20 * * * * ?"}
                                            onChange={e => mutateEncounterType(idx, { CronSchedule: e.target.value })}
                                            required
                                            label="Cron Expression"
                                        />
                                    </div>
                                    {
                                        encounterType.EncounterType === "CAD" &&
                                        <div className="tableSection sectionName" style={{ marginTop: "10px" }}>
                                            <div className="sectionTitle">
                                                <div className="title">Exclude</div>
                                            </div>
                                            <div onClick={() => mutateEncounterType(idx, { ExcludeCallTakerInfo: !encounterType.ExcludeCallTakerInfo })}
                                                 className={`tableCell switchCell ${!!encounterType.ExcludeCallTakerInfo ? 'switchOn' : 'switchOff'}`}>
                                                <div className="title">Call Taker Info</div>
                                                <div className={`accessory accessorySwitch ${!!encounterType.ExcludeCallTakerInfo ? 'on' : 'off'}`}>
                                                    <div className="switchThumb" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className={"remove"} onClick={() => removeEncounterType(idx)}>
                                    <div className="icon" />
                                </div>
                            </div>

                        ))
                    }

                    <div className="tableSection sectionName">
                        <div onClick={addEncounterType} className="button tint medium addEncounterType">
                            <div className="title">Add Encounter Type</div>
                        </div>
                    </div>


                    <div className="tableSection">
                        <div className={`button large tintProminent`} onClick={saveConnector}>
                            <div className="title">{existingConnector ? "Update Connector" : "Create Connector"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
