import React, {useEffect, useState} from "react";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { API } from "aws-amplify";

function CreateEditPublicFeedbackBoard(props) {
    const FEEDBACKS = {
        'POSITIVE': 'Positive',
        'NEUTRAL': 'Neutral',
        'NEGATIVE': 'Negative',
        'MIXED': 'Mixed'
    };

    const PERSONNEL_TYPES = {
        'CALLTAKER': 'Calltaker',
        'RESPONDER': 'Responder',
        //'INVESTIGATOR': 'Investigator',
        'ALL': 'All',
        'NONE': 'None'
    };

    const [selectedFeedbacks, setSelectedFeedbacks] = useState({
        'POSITIVE': true,
        'NEUTRAL': false,
        'NEGATIVE': false,
        'MIXED': false
    });
    const [selectedPersonnelType, setSelectedPersonnelType] = useState(Object.fromEntries(Object.keys(PERSONNEL_TYPES).map(f => [f, true])));
    const [feedbackName, setFeedbackName] = useState('');
    const [viewType, setViewType] = useState('LIST');
    const [showPersonnelName, setShowPersonnelName] = useState(true);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentSetting, setCurrentSetting] = useState(null);

    const createFeedbackSettingData = () => ({
        ...currentSetting,
        name: feedbackName,
        viewType: viewType,
        showPersonnelName: viewType === 'FOCUS' ? showPersonnelName: true,
        personnelType: Object.keys(selectedPersonnelType).filter(f => selectedPersonnelType[f]).join(','),
        feedbackType: Object.keys(selectedFeedbacks).filter(f => selectedFeedbacks[f]).join(','),
    });

    const updateFeedback = feedback => {
        const newSelectedFeedbacks = { ...selectedFeedbacks };
        newSelectedFeedbacks[feedback] = !newSelectedFeedbacks[feedback];
        setSelectedFeedbacks(newSelectedFeedbacks);
    }

    const updatePersonnelType = personnelType => {
        const newSelectedPersonnelType = { ...selectedPersonnelType };
        newSelectedPersonnelType[personnelType] = !newSelectedPersonnelType[personnelType];
        setSelectedPersonnelType(newSelectedPersonnelType);
    }

    const onCancel = () => {
        props.history.goBack();
    }

    const isAnyPersonnelTypeSelected = () => {
        return Object.values(selectedPersonnelType).some(personnel => personnel);
    };
    const isAnyFeedbackTypeSelected = () => {
        return Object.values(selectedFeedbacks).some(feedback => feedback);
    };
    const saveFeedbackSetting = () => {
        let error = null;
        if (!isAnyFeedbackTypeSelected()) error= "Feedback Types not selected";
        if (!isAnyPersonnelTypeSelected()) error= "Personnel Types not selected";
        if (feedbackName==='') error = "Feedback Name is required";
        if (error) {
            toast.error(error, { position: toast.POSITION.TOP_CENTER });
            return;
        }

        const DATA = createFeedbackSettingData();
        setSaving(true);
        let savingRequest;

        if (currentSetting?.id) {
            savingRequest = API.put('Core', `/api/v1/public-feedback-board/${currentSetting.id}`, { body: DATA })
                .then(r => {
                    const SETTING = r;

                    if (SETTING) {
                        setCurrentSetting(SETTING);
                        toast.success("Feedback Setting Updated!", { position: toast.POSITION.TOP_CENTER });
                    }
                })
        }
        else {

            savingRequest = API.post('Core', '/api/v1/public-feedback-board', { body: DATA })
                .then(r => {
                    const SETTING = r;

                    if (SETTING) {
                        setCurrentSetting(SETTING);
                        toast.success("Feedback Setting Created!", { position: toast.POSITION.TOP_CENTER });
                    }
                })
        }

        savingRequest.finally(() => setSaving(false));
    }
    useEffect(() => {

        const ID = props?.match?.params?.id;
        if (!ID) return;

        setLoading(true);
        API.get('Core', `/api/v1/public-feedback-board/${ID}`)
            .then(r => {
                setCurrentSetting(r || {});
                if (r) {

                    const SELECTED_FEEDBACK = Object.keys(PERSONNEL_TYPES).reduce((acc, key) => {
                        acc[key] = false;
                        return acc;
                    }, {});

                    const SELECTED_PERSONNEL_TYPE = Object.keys(PERSONNEL_TYPES).reduce((acc, key) => {
                        acc[key] = false;
                        return acc;
                    }, {});


                    // Debugging: Log the data being set
                    console.log("Setting feedbackName:", r.name);
                    console.log("Setting viewType:", r.viewType);
                    console.log("Setting showPersonnelName:", r.showPersonnelName);
                    console.log("Selected Feedbacks:", r.feedbackType.split(','));
                    console.log("Selected Personnel Types:", r.personnelType.split(','));

                    setFeedbackName(r.name);
                    setViewType(r.viewType);
                    setShowPersonnelName(r.showPersonnelName);

                    r.feedbackType.split(',').forEach(feedback => {
                        if (FEEDBACKS[feedback]) {
                            SELECTED_FEEDBACK[feedback] = true;
                        }
                    });
                    setSelectedFeedbacks(SELECTED_FEEDBACK);

                    r.personnelType.split(',').forEach(personnel => {
                        if (PERSONNEL_TYPES[personnel]) {
                            SELECTED_PERSONNEL_TYPE[personnel] = true;
                        }
                    });

                    setSelectedPersonnelType(SELECTED_PERSONNEL_TYPE);
                }
            })
            .finally(() => setLoading(false));
    }, []);

    const handleViewTypeChange = (event) => {
        setViewType(event.target.value);
        if (event.target.value === 'LIST') {
            setShowPersonnelName(true);
        }
    };

    const handleShowPersonnelNameChange = (event) => {
        setShowPersonnelName(event.target.value==='true');
    };
    return (
        <div className='publicFeedbackView'>
            <div className="navBar navBarFeedback">
                <div className="title navBarFeedbackTitle">Public Feedback Board Settings</div>
            </div>
            {!loading ? <>
            <section>
                <h3 className='publicFeedbackNameHeader'>Public Feedback Board Name</h3>
                <div className='publicFeedbackNameContainer'>
                    <TextField value={feedbackName} onChange={event => setFeedbackName(event.target.value)}
                        variant="outlined"
                        className="publicFeedbackNameInput"
                    />
                    <h4>This is only used for Settings purposes</h4>
                </div>
            </section>
            <section>
                <h3 className='publicFeedbackHeader'>View Type</h3>
                <div className='publicFeedbackContainer'>
                    <div className='publicFeedbackOption'>
                        <label>
                            <input
                                type="radio"
                                name="viewType"
                                value="LIST"
                                checked={viewType === 'LIST'}
                                onClick={handleViewTypeChange}
                            />List
                        </label>
                    </div>
                    <div className='publicFeedbackOption'>
                        <label>
                            <input
                                type="radio"
                                name="viewType"
                                value="FOCUS"
                                checked={viewType === 'FOCUS'}
                                onClick={handleViewTypeChange}
                            /> Single entry (focus)
                        </label>
                    </div>
                </div>
            </section>
            <section hidden={viewType === 'LIST'}>
                <h3 className='publicFeedbackHeader'>Show Personnel Name</h3>
                <div className='publicFeedbackContainer'>
                    <div className='publicFeedbackOption'>
                        <label>
                            <input
                                type="radio"
                                name="showPersonnelName"
                                value='true'
                                checked={showPersonnelName === true}
                                onClick={handleShowPersonnelNameChange}
                            /> On
                        </label>
                    </div>
                    <div className='publicFeedbackOption'>
                        <label>
                            <input
                                type="radio"
                                name="showPersonnelName"
                                value='false'
                                checked={showPersonnelName === false}
                                onClick={handleShowPersonnelNameChange}
                            /> Off
                        </label>
                    </div>
                </div>
            </section>
                <section>
                    <h3 className='publicFeedbackHeader'>Personnel Types</h3>
                    <div className='publicFeedbackContainer'>
                        <FormGroup className='publicFeedbackPersonnelType' column>
                            {Object.keys(PERSONNEL_TYPES).map((key, i) => (
                                <FormControlLabel
                                    className='publicFeedbackPersonnelTypeOption'
                                    key={`emailDigestSubscriptionPersonnelOption${i}`}
                                    label={PERSONNEL_TYPES[key]}
                                    control={<Checkbox checked={selectedPersonnelType[key]} />}
                                    onClick={() => updatePersonnelType(key)}
                                />
                            ))}
                        </FormGroup>
                    </div>
                </section>
                <section>
                    <h3 className='publicFeedbackHeader'>Types of Feedback</h3>
                    <div className='publicFeedbackContainer'>
                        <FormGroup className='publicFeedbackPersonnelType' column>
                            {Object.keys(FEEDBACKS).map((key, i) => (
                                <FormControlLabel
                                    className='publicFeedbackPersonnelTypeOption'
                                    key={`emailDigestSubscriptionFeedbackOption${i}`}
                                    label={FEEDBACKS[key]}
                                    control={<Checkbox checked={selectedFeedbacks[key]} />}
                                    onClick={() => updateFeedback(key)}
                                />
                            ))}
                        </FormGroup>
                    </div>
                </section>
            <div className="actionBar " style={{background:"white"}}>
                <div className="actions">
                    <div onClick={onCancel} className="button dismiss medium">
                        <div className="title">Cancel</div>
                    </div>

                    <div onClick={!saving ? saveFeedbackSetting : null} className={`button medium ${ !saving ? 'confirm' : 'dismiss disabled'}`}>
                    <div className="title">Save</div>
                    </div>
                </div>
            </div>
            </> : <CircularProgress/>}
        </div>
    )
}
export default CreateEditPublicFeedbackBoard;