import React, { Component } from "react";
import { API } from 'aws-amplify';
import FlatfileImporter from "flatfile-csv-importer";
import _ from "lodash";

const LICENSE_KEY = "cb0939d7-4cdf-41f4-a4e7-51ea6eb950c8";

class Importer extends Component {
    constructor(props) {
        super();
        this.importer = null;
        this.importPath = props.importPath;
    }

    getFields() {
        let fields = this.props.fields;
        _.forEach(fields, function (field, k) {
            if (field.validators) {
                _.forEach(field.validators, function (validator, j) {
                    if (validator.regex) {
                        validator.regex = new RegExp(validator.regex, "i");
                    }
                })
            }
        });
        return fields;
    }

    launch() {
        this.importer = new FlatfileImporter(LICENSE_KEY, {
            fields: this.getFields(),
            type: this.props.title,
            allowInvalidSubmit: true,
            managed: true,
            allowCustom: true,
            disableManualInput: false
        });
        this.importer.open();
        this.importer.on('complete', function (data) {
            this.importer.displayLoader();
            API.post('Core', this.importPath, { body: data })
                .then(response => {
                    if (response.status === "SUCCESS") {
                        this.importer.displaySuccess(response.rowsProcessed + " Items succesfully processed.");
                        if (this.props.onSuccess) {
                            this.props.onSuccess(response);
                        }
                    }
                    else {
                        this.importer.displayError("Error Importing Data");
                    }
                },
                    error => {
                        this.importer.displayError("Error Importing Data");
                    }
                );
        }.bind(this));

    }

    render() {
        return (
            <div className="importer" onClick={this.launch.bind(this)}>
                {this.props.children}
            </div>
        );
    }
}

export default Importer;
