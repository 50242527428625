import React from 'react';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import Select from "react-select";

import InputCell from "@/common/form/InputCell";
import { TIMEZONES } from "@/utils/utils";

export default function CreateTenant({ onSuccess }) {

    const companyNameRef = React.createRef()
    const salesforceIdRef = React.createRef()
    const firstNameRef = React.createRef()
    const lastNameRef = React.createRef()
    const emailRef = React.createRef()
    const passwordRef = React.createRef()
    const [timezoneObj, setTimezoneObj] = React.useState(TIMEZONES[0]);

    const onTimezoneChange = (obj, action) => {
        setTimezoneObj(obj);
    }

    async function submitSignUp(e) {
        e.preventDefault();
        let signUpInfo = {
            companyName: companyNameRef.current.value,
            salesforceId: salesforceIdRef.current.value,
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            timezoneName: timezoneObj.value,
            phone: null
        };

        await API.post('Core', '/api/v1/support-tools/tenants/create', { body: signUpInfo })
            .then(() => {
                toast("Successfully created a new tenant!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
                onSuccess();
            })
            .catch((err) => {
                let errorMessage = err.response.data.message;
                try{
                    let [emptyTenantMessage]= JSON.parse(errorMessage);
                    toast(emptyTenantMessage, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
                catch (e) {
                    toast(errorMessage, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            });
    }

    return (
        <div>
            <div className="content">
                <div className="tableView">
                    <div className="tableSection">
                        <InputCell
                            placeholder={"Ambulance Corp"}
                            required
                            id="firstName"
                            label="Company Name"
                            inputRef={companyNameRef}
                        />
                    </div>

                    <div className="tableSection">
                        <div className="sectionTitle">Timezone</div>
                        <Select className="cell selectCell" classNamePrefix="selectCell"
                            onChange={onTimezoneChange}
                            defaultValue={timezoneObj}
                            value={timezoneObj}
                            options={TIMEZONES}
                        />

                    </div>

                    <div className="tableSection">
                        <InputCell
                            placeholder={"Salesforce Id"}
                            label="Salesforce Id"
                            inputRef={salesforceIdRef}
                        />
                    </div>

                    <div className="tableSection">
                        <InputCell
                            placeholder={"John"}
                            required
                            id="firstName"
                            label="First Name"
                            inputRef={firstNameRef}
                        />

                        <InputCell
                            placeholder={"Smith"}
                            required
                            id="lastName"
                            label="Last Name"
                            inputRef={lastNameRef}
                        />
                    </div>

                    <div className="tableSection">
                        <InputCell
                            placeholder={"name@domain.com"}
                            required
                            inputProps={{
                                autocomplete: "username"
                            }}
                            id="email"
                            label="Email"
                            inputRef={emailRef}
                        />

                        <InputCell
                            required
                            id="password"
                            label="Optional Password"
                            inputRef={passwordRef}
                            type="password"
                        />

                        <div className="cellFootnote">
                            <b>OPTIONAL</b> <br />
                            * If you set the password then you will need to share it with the end user. They will be asked to verify their email address on first login. Password must have at least 1 uppercase, 1 lower case, 1 number, and 1 special character.<br />
                            * If you <b>DO NOT</b> set a password then the system will generate one and email the user.
                        </div>
                    </div>

                    <div className="tableSection">
                        <div className={`button large tintProminent`} onClick={submitSignUp}>
                            <div className="title">Create Tenant</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}